import styles from './SaleTag.module.scss'
import Link from 'next/link'

import Tag from '../../components/icons/tag.svg'

const SaleTag = ({special_title, special_amount, special_id, className, }) => {
	if (`${special_amount}` !== "null") {
		return (
			<Link href={`/shop?special=${special_id}`} className={`${styles.tag} ${className}`}>
				<Tag />
				{special_amount}
			</Link>
		)
	}
}

export default SaleTag;

